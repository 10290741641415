import { CButton, CCard, CCardBody, CCardTitle, CCol, CRow } from "@coreui/react";
import { IDynamicPerson, PeoplePicker, PersonType, UserType } from "@microsoft/mgt-react";
import { useRef, useState, useEffect, useContext } from "react";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { arraysAreEqual } from "../helper/Helper";
import { ConfigurationService } from "../../services/ConfigurationService";
import { GraphService } from "../../services/GraphService";
import { Role } from "../../common/interfaces";
import { EasyContext } from "../context/EasyContext";

import { v4 as uuid } from 'uuid';

const RenderRoleManager = (props: { configuration, setConfiguration, setSuccessMsg, addToast, setErrorMsg, errorToast, successfulToast }) => {
    const { configuration, setConfiguration, setSuccessMsg, addToast, setErrorMsg, errorToast, successfulToast } = props;

    const { t } = useTranslation();

    const { accessToken } = useContext(EasyContext);
    const graphService = new GraphService(accessToken, false);

    const easyContext = useContext(EasyContext);

    const configurationService = new ConfigurationService(easyContext.accessToken);

    const peoplePickerRef = useRef(null);
    const [selectedPeople, setSelectedPeople] = useState<IDynamicPerson[]>([]);
    const [enableSaveRoles, setEnableSaveRoles] = useState(false);
    const [pristineRoles, setPristineRoles] = useState<Role[]>([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [onConfirm, setOnConfirm] = useState(() => () => { return }); // Default no-op function
    useEffect(() => {
        const fetchPeople = async () => {
            setPristineRoles([...configuration?.roles ?? []]);
            const tagManagerRole = configuration?.roles?.find(role => role.roleName === "TagsManager");
            if (tagManagerRole?.users) {
                try {
                    const peoplePromises = tagManagerRole.users.map(userId => graphService.getUser(userId));
                    const people = await Promise.all(peoplePromises);
                    setSelectedPeople(people);
                    setIsInitialized(true);
                } catch (error) {
                    console.error("Error fetching users:", error);
                }
            }
        };

        if (configuration && !isInitialized) {
            fetchPeople();
        }
    }, [configuration]);

    useEffect(() => {
        if (!pristineRoles?.find(role => role.roleName === "TagsManager")) {
            pristineRoles.push(
                {
                    id: uuid,
                    roleName: "TagsManager",
                    permissions: null,
                    users: [
                    ]
                }
            );
        }
        if (configuration && isInitialized) {
            setEnableSaveRoles(!arraysAreEqual(pristineRoles?.find(role => role.roleName === "TagsManager").users, selectedPeople.map(item => item.id)));
        }
    }, [pristineRoles]);

    const handleSelectionChanged = (event) => {
        const selection = [...event.detail];
        setSelectedPeople(selection);
        setEnableSaveRoles(!arraysAreEqual(pristineRoles?.find(role => role.roleName === "TagsManager").users, selection.map(item => item.id)));
    };


    const openModal = (action: () => void) => {
        setOnConfirm(() => action);
        setModalVisible(true);
    };

    const saveRoles = () => {
        const updateConfig = async () => {
            const config = { ...configuration };
            if (!config?.roles?.find(role => role.roleName === "TagsManager")) {
                config?.roles?.push(
                    {
                        id: uuid,
                        roleName: "TagsManager",
                        permissions: null,
                        users: [
                        ]
                    }
                );
            }
            config.roles.find(role => role.roleName === "TagsManager").users = selectedPeople.map(item => item.id)
            setPristineRoles([...config?.roles ?? []]);
            await configurationService.UpdateRoles(configuration?.id, config.roles)
                .then(() => {
                    setConfiguration(config);
                    setSuccessMsg(t("SettingsSaved"));
                    addToast(successfulToast);
                }).catch((e) => {
                    setErrorMsg(e.message.message);
                    addToast(errorToast);
                });
        };
        updateConfig();
    };

    return (<>
        <CCard>
            <CCardBody>
                <CCardTitle>{t("EasyDirectory.ManageRoles")}</CCardTitle>
                <CRow className="tcf-acc-voiceapp">
                    <CCol xs={4}>{t("EasyDirectory.Roles.TagManager")}:</CCol>
                    <CCol xs={8}>
                        <PeoplePicker
                            className="people-picker"
                            ref={peoplePickerRef}
                            type={PersonType.person}
                            userFilters=" "
                            userType={UserType.user}
                            selectionChanged={handleSelectionChanged}
                            selectionMode="multiple"
                            selectedPeople={selectedPeople}
                            showMax={6}
                        />
                    </CCol>
                </CRow>
                <CRow className="tcf-flexbox tcf-align-end">
                    <CCol xs={2} className="tcf-flexbox tcf-align-end">
                        <CButton
                            disabled={!enableSaveRoles}
                            className="mr-1 mb-1"
                            style={{
                                backgroundColor: !enableSaveRoles && "Gray",
                                borderColor: !enableSaveRoles && "Gray"
                            }}
                            color="primary"
                            onClick={() => openModal(saveRoles)}
                        >
                            {t("Button.SaveRoles")}
                        </CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <ConfirmationModal visible={modalVisible}
            toggleModal={() => setModalVisible(false)}
            onConfirm={onConfirm} />
    </>
    );
};

export default RenderRoleManager;