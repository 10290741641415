import { useCallback, useState, useMemo, useContext } from 'react'
import { CCard, CCardBody, CCardHeader, CButton, CAlert, CSpinner, CImage } from '@coreui/react'
import { MigrationStepProps } from '../types'
import { t } from 'i18next'
import { SubscriptionService } from '../../../services/SubscriptionService'
import { EasyContext } from '../../../components/context/EasyContext'
import { useTranslation } from 'react-i18next'
import EasyDirectoryLogo from '../../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'

export const MigrationStep: React.FC<MigrationStepProps> = ({
  migrateTags,
  onNext,
  onPrevious,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const { t } = useTranslation();
  const { accessToken } = useContext(EasyContext)

  const subscriptionService = useMemo(() => new SubscriptionService(accessToken), [accessToken])

  const handleMigrate = useCallback(async () => {
    setIsLoading(true)
    setError(null)

    try {
      const success = await subscriptionService.migrateEasyDirectory(migrateTags)

      if (success) {
        onNext()
      } else {
        setError(t('EasyDirectory.MigrationWizard.Migration.Error'))
      }
    } catch (err) {
      console.error('Migration error:', err)
      setError(t('EasyDirectory.MigrationWizard.Migration.Error'))
    } finally {
      setIsLoading(false)
    }
  }, [migrateTags, onNext, subscriptionService])

  return (
    <>
    <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
    <CCard>
      <CCardBody>
        <h4>{t('EasyDirectory.MigrationWizard.Migration.Title')}</h4>
        {error && <CAlert color="danger">{error}</CAlert>}
        <p className="mb-3">{t('EasyDirectory.MigrationWizard.Migration.Description')}</p>
        <p className="mb-3">{t('EasyDirectory.MigrationWizard.Migration.TimeEstimate')}</p>
        <ul className="mb-4">
          <li>{t('EasyDirectory.MigrationWizard.Migration.Items.Users')}</li>
          <li>{t('EasyDirectory.MigrationWizard.Migration.Items.Contacts')}</li>
          <li>{t('EasyDirectory.MigrationWizard.Migration.Items.Settings')}</li>
        </ul>
        <p className="mb-4">{t('EasyDirectory.MigrationWizard.Migration.AfterMigration')}</p>
        <div className="d-flex justify-content-between">
          <CButton color="light" onClick={onPrevious}>
            {t('EasyDirectory.MigrationWizard.Common.Previous')}
          </CButton>
          <CButton color="primary" onClick={handleMigrate} disabled={isLoading}>
            {isLoading ? (
              <>
                <CSpinner size="sm" className="me-2" />
                {t('EasyDirectory.MigrationWizard.Migration.Migrating')}
              </>
            ) : (
              t('EasyDirectory.MigrationWizard.Migration.StartButton')
            )}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
    </>
  )
}

