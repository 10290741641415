import { EasyTag, Seat, TeamsAssignment } from "../../common/interfaces";

export const convertToTag = (id: string, teamsAssignment: TeamsAssignment): EasyTag => {
    return teamsAssignment?.availableTags?.filter((option) => option.id === id)[0];
};


export const countSeats = (seats: Seat[]) => {
    const uniqueSeats = new Set();
    seats.forEach(seat => {
      if (seat?.occupant) {
        uniqueSeats.add(seat.occupant.email);
      } else if (seat?.reservation) {
        uniqueSeats.add(seat.reservation.email);
      }
    });
    return uniqueSeats.size;
  };

  
/**
 * Compares two arrays if the values are equals
 * Should be used for arrays of objective types
 *
 * @export
 * @function arr1 & arr2 are objective arrays of the elements in the array (are made for objects)
 * @param arr1
 * @param arr2
 * @returns true if both arrays contain the same items or if both arrays are null or empty
 */
export const arraysAreEqual = (arr1: any[], arr2: any[]) => JSON.stringify(arr1?.sort()) === JSON.stringify(arr2?.sort());