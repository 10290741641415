import { BrowserRouter, Routes, Route } from "react-router-dom";
import AnonymousLayout from "./layout/AnonymousLayout";
import DefaultAuthenticatedLayout from "./layout/DefaultAuthenticatedLayout";
import Dashboard from "./views/dashboard/Dashboard";
import EasyCallReport from "./views/easycallreport/EasyCallReport";
import EasyDirectory from "./views/easydirectory/EasyDirectory";
import MigrationWizard from "./views/migration";
import HomePageLanding from "./views/pages/HomePageLanding";
import Page404 from "./views/pages/page404/Page404";
import SubscriptionOnboarding from "./views/subscriptions/Onboarding";
import SubscriptionManager from "./views/subscriptions/SubscriptionManager";
import EasyCallReportAuthenticatedLayout from "./layout/EasyCallReportAuthenticatedLayout";
import EasyDirectoryAuthenticatedLayout from "./layout/EasyDirectoryAuthenticatedLayout";
import EasyCallReportOnboarding from "./views/subscriptions/onboarding/EasyCallReport";
import EasyDirectoryOnboarding from "./views/subscriptions/onboarding/EasyDirectory";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<DefaultAuthenticatedLayout />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route
                        path="/subscriptions"
                        element={<SubscriptionManager />}
                    />
                    <Route
                        path="/subscriptions/onboarding/easycallreport"
                        element={<EasyCallReportOnboarding />}
                    />
                    <Route
                        path="/subscriptions/onboarding/easydirectory"
                        element={<EasyDirectoryOnboarding />}
                    />
                    <Route
                        path="/subscriptions/:subscriptionId"
                        element={<SubscriptionManager />}
                    />
                    <Route
                        path="/easydirectory/migration"
                        element={<MigrationWizard />}
                    />
                </Route>
                <Route element={<EasyCallReportAuthenticatedLayout />}>
                    <Route
                        path="/easycallreport"
                        element={<EasyCallReport />}
                    />
                </Route>
                <Route element={<EasyDirectoryAuthenticatedLayout />}>
                    <Route
                        path="/easydirectory"
                        element={<EasyDirectory />}
                    />
                </Route>
                <Route element={<AnonymousLayout />}>
                    <Route
                        path="/subscriptions/onboarding"
                        element={<SubscriptionOnboarding />}
                    />
                    <Route
                        path="/store/landing"
                        element={<SubscriptionOnboarding />}
                    />
                    <Route
                        path="/landing"
                        element={<HomePageLanding />}
                    />
                    <Route path="*" element={<Page404 />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}