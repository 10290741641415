import {
  CContainer,
  CHeader,
  CHeaderBrand,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import logo from '../assets/images/Def_TCF_New_Logo_GW.png'
import { CSidebarBrand } from '@coreui/react'
import logoNegative from '../assets/images/Def_TCF_New_Logo.png'
import { sygnet } from '../assets/brand/sygnet'
import { useIsAuthenticated } from '@azure/msal-react'
import Navbar from './Navbar'

const AppHeader = () => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <CHeader position="sticky" className="mb-4 tcf-header">
      <CContainer fluid>
        <CSidebarBrand className="d-none d-md-flex">
          <img className="sidebar-brand-full" src={logoNegative} height={40} />
          <CIcon className="sidebar-brand-full" icon={logoNegative} height={100} />
          <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
        </CSidebarBrand>
        {isAuthenticated ? <>
          <Navbar />
          <CHeaderBrand className="mx-auto d-md-none">
            <CIcon icon={logo} height={48} />
          </CHeaderBrand>
          </> : <></>}
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
