import {
    CButton,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter
} from '@coreui/react';
import { useTranslation } from 'react-i18next';

interface ConfirmationModalProps {
    visible: boolean;
    toggleModal: () => void;
    onConfirm: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    visible,
    toggleModal,
    onConfirm
}) => {
    const { t } = useTranslation();

    const handleConfirm = () => {
        onConfirm();
        toggleModal();
    };

    return (
        <CModal
            visible={visible}
            onClose={toggleModal}
            aria-labelledby="ConfirmationModalLabel"
        >
            <CModalHeader>
                <CModalTitle id="ConfirmationModalLabel">{t('Modal.SaveChanges.Title')}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <p>{t('Modal.SaveChanges.Text')}</p>
            </CModalBody>
            <CModalFooter>
                <CButton color="secondary" onClick={toggleModal}>
                    {t('Modal.SaveChanges.Cancel')}
                </CButton>
                <CButton color="primary" onClick={handleConfirm}>
                    {t('Modal.SaveChanges.Submit')}
                </CButton>
            </CModalFooter>
        </CModal>
    );
};

export default ConfirmationModal;