import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Navbar.css";
import { useContext, useState } from "react";
import { AppHeaderDropdown } from "./header";
import { EasyContext } from "./context/EasyContext";
import { Login } from "@microsoft/mgt-react";
import { useIsAuthenticated } from "@azure/msal-react";

const Navbar = () => {
    const easyContext = useContext(EasyContext);
    const isAuthenticated = useIsAuthenticated();
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1150) {
            setShowMenu(false);
        }
    };

    return (isAuthenticated && (
        <nav className="nav">
            <div className={`nav__menu ${showMenu ? "show-menu" : ""}`} id="nav-menu">

                <ul className="nav__list">
                    <li className="nav__item">
                        <NavLink to="/" className="nav__link">
                            Dashboard
                        </NavLink>
                    </li>
                    {easyContext.productLicenses?.EasyCallReport?.isLicensed && (
                        <li className="nav__item">
                            <NavLink to="/easycallreport" className="nav__link" reloadDocument>
                                Easy Call Report
                            </NavLink>
                        </li>)}
                    {easyContext.productLicenses?.EasyDirectory?.isLicensed && (
                        <li className="nav__item">
                            <NavLink to="/easydirectory" className="nav__link" reloadDocument>
                                Easy Directory
                            </NavLink>
                        </li>)}
                    <li className="nav__item">
                        <NavLink to="/subscriptions" className="nav__link" reloadDocument>
                            Subscriptions
                        </NavLink>
                    </li>
                    <li>
                        <Login />
                    </li>
                </ul>

                <div className="nav__close" id="nav-close" onClick={toggleMenu}>
                    <IoClose />
                </div>
            </div>

            <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
                <IoMenu />
            </div>
        </nav>
    )
    );
};

export default Navbar;