import { APIM } from "../common/constants";
import { ICall } from "../common/interfaces";
import axios, { AxiosError } from "axios";

export class TelemetryService {
  constructor(access_token: string) {
    if (access_token !== null) {
      if (access_token.startsWith("Bearer ")) {
        this.accessToken = access_token;
      }
      else {
        this.accessToken = "Bearer " + access_token;
      }
    }
  }

  accessToken: string;

  async getCallsOfLast30Days(): Promise<ICall[]> {
    const calls: ICall[] = [];

    try {
      const queryParams = new URLSearchParams();
      const date: string = encodeURIComponent((new Date).toLocaleDateString('pt-br').split('/').reverse().join('-'));

      queryParams.append("date", date);

      const response = await axios.get(APIM + 'easycallreport/calls?' + queryParams.toString(), {
        headers: {
          'Authorization': this.accessToken,
          'Content-Type': 'application/json'
        }
      });

      return response.data.Documents as ICall[];
    } catch (e: unknown) {
      throw new AxiosError(e as string);
    }
  }
}
