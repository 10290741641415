import {
  CButton,
  CCard,
  CCardBody,
  CCardImage,
  CCardText,
  CContainer,
  CRow,
  CCol
} from '@coreui/react'
import EasyCallReportLogo from '../../assets/brand/TCF_EasyCallReport_Logo_RGB.png'
import EasyDirectoryLogo from '../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'
import { useTranslation } from "react-i18next";

import "./Dashboard.scss";
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { EasyContext } from '../../components/context/EasyContext';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const easyContext = useContext(EasyContext);

  const Greeting = () => {
    const hours = new Date().getHours()
    const isMorningTime = hours > 6 && hours <= 10
    const isLunchTime = hours >= 10 && hours <= 17
    const isEveningTime = hours >= 17 && hours < 22

    if (isMorningTime) {
      return <>{t("GoodMorning")}</>;
    } else if (isLunchTime) {
      return <>{t("Welcome")}</>;
    } else if (isEveningTime) {
      return <>{t("GoodEvening")}</>;
    } else {
      return <>{t("GoodNight")}</>;
    }
  }

  return (
    <div>
      <br />
      <h2><Greeting /> {easyContext.userDisplayName}</h2>

      <CRow>
        {easyContext.productLicenses?.EasyCallReport?.isLicensed && <CCol xs={12} md={6} className="mb-4">
          <CCard className="h-100 d-flex flex-column" onClick={() => navigate('/easycallreport')}>
            <CCardImage
              src={EasyCallReportLogo}
              className="tcf-card-logo p-4"
            />
            <CCardBody className="d-flex flex-column">
              <div className="flex-grow-1">
                <CCardText>
                  {t('IntroductionShort')}
                </CCardText>
              </div>
              <div className="mt-auto pt-3">
                <CButton href="/easycallreport" color="dark">
                  {t('Button.Settings')}
                </CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol>}
        {easyContext.productLicenses?.EasyDirectory?.isLicensed && <CCol xs={12} md={6} className="mb-4">
          <CCard className="h-100 d-flex flex-column" onClick={() => navigate('/easydirectory')}>
            <CCardImage
              src={EasyDirectoryLogo}
              className="tcf-card-logo p-4"
            />
            <CCardBody className="d-flex flex-column">
              <div className="flex-grow-1">
                <CCardText>
                  {t('EasyDirectory.Introduction')}
                </CCardText>
              </div>
              <div className="mt-auto pt-3">
                <CButton href="/easydirectory" color="dark">
                  {t('Button.Settings')}
                </CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol>}
      </CRow>
    </div>
  )
}

export default Dashboard