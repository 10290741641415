import { CButton, CFooter, CPopover } from '@coreui/react'
import * as React from 'react';
import { Version } from '../common/constants';
import { EasyContext } from './context/EasyContext';
import { useContext } from 'react';

const AppFooter = () => {
  const easyContext = useContext(EasyContext);
  const supportContent = "Tenant Id: " + easyContext.tenantId;

  return (
    <CFooter>
      <div>
        <span className="ms-1 tcf-footer-copyright">&copy; {(new Date()).getFullYear()} TwinCap First AG - Version {Version} - 
          </span>
          <CPopover
            content={supportContent}
            placement="top">
            <CButton color="link">Support Info</CButton>
          </CPopover>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
