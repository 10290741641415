import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { ProductConfiguration } from '../common/constants';
import EasyContextHandler from '../components/context/EasyContextHandler';
import { MsalProvider } from "@azure/msal-react";
import { useEffect, useState } from "react";

const msalConfigurationCenterConfig: Configuration = {
  auth: {
    clientId: ProductConfiguration.AdminPortal.ApplicationId,
    redirectUri: ProductConfiguration.AdminPortal.RedirectUrl,
    authority: ProductConfiguration.AdminPortal.Authority
  },
};

const pca = new PublicClientApplication(msalConfigurationCenterConfig);

const DefaultAuthenticatedLayout = () => {
  const [initialized, setInitialized] = useState(false); 

  useEffect(() => {
    const initializeMsal = async () => {
      await pca.initialize(); // Initialize MSAL instance
      setInitialized(true);
    };
  
    initializeMsal();  
  }, []);
  
  return (
    <MsalProvider instance={pca}>
      <EasyContextHandler authScopes={ProductConfiguration.AdminPortal.Scopes} />
    </MsalProvider>
  )
}

export default DefaultAuthenticatedLayout
