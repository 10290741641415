import { CCard, CCardBody, CCardHeader, CButton, CCardImage, CCardSubtitle, CCardTitle } from '@coreui/react'
import { StepProps } from '../types'
import { t } from 'i18next'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import EasyDirectoryWallpaper from '../../../assets/images/Wallpaper_ED.jpg'

const WelcomeStepComponent: React.FC<StepProps> = ({ onNext }) => {
  const { t } = useTranslation();
  return (
    <CCard>
      <CCardImage orientation="top" src={EasyDirectoryWallpaper} className='tcf-ed-welcome-wallpaper' />
      <CCardBody>
      <CCardTitle>{t('EasyDirectory.MigrationWizard.Welcome.Title')}</CCardTitle>
      <CCardSubtitle className="mb-2 text-body-secondary">{t('EasyDirectory.MigrationWizard.Welcome.SubTitle')}</CCardSubtitle>
        <h4 className="mb-4"></h4>
        <p className="mb-4">{t('EasyDirectory.MigrationWizard.Welcome.Description')}</p>
        <p className="mb-4">{t('EasyDirectory.MigrationWizard.Welcome.WhatToExpect')}</p>
        <ol className="mb-4">
          <li>{t('EasyDirectory.MigrationWizard.Welcome.Steps.Subscription')}</li>
          <li>{t('EasyDirectory.MigrationWizard.Welcome.Steps.Tags')}</li>
          <li>{t('EasyDirectory.MigrationWizard.Welcome.Steps.Final')}</li>
        </ol>
        <CButton color="primary" onClick={onNext}>
          {t('EasyDirectory.MigrationWizard.Welcome.GetStarted')}
        </CButton>
      </CCardBody>
    </CCard>
  )
}

WelcomeStepComponent.displayName = 'WelcomeStep'
export const WelcomeStep = memo(WelcomeStepComponent)

