import { CCard, CCardBody, CCardHeader, CButton, CImage } from '@coreui/react'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next';
import EasyDirectoryLogo from '../../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'

interface FinishStepProps {
  onFinish: () => void
}

export const FinishStep: React.FC<FinishStepProps> = ({ onFinish }) => {
  const { t } = useTranslation();

  return (
  <>
    <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
    <CCard>
      <CCardHeader>{t('EasyDirectory.MigrationWizard.Finish.Title')}</CCardHeader>
      <CCardBody>
        <h4 className="mb-4">{t('EasyDirectory.MigrationWizard.Finish.Congratulations')}</h4>
        <p className="mb-4">{t('EasyDirectory.MigrationWizard.Finish.Description')}</p>
        <h3 className="mb-3">{t('EasyDirectory.MigrationWizard.Finish.WhatNext')}</h3>
        <ul className="mb-4">
          {(
            t('EasyDirectory.MigrationWizard.Finish.NextSteps', { returnObjects: true }) as string[]
          ).map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>
        <CButton color="primary" onClick={onFinish}>
          {t('EasyDirectory.MigrationWizard.Finish.Dashboard')}
        </CButton>
      </CCardBody>
    </CCard>
    </>
  )
}

