import { useState, useEffect, useContext } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CButton,
  CAlert,
  CFormCheck,
  CListGroup,
  CListGroupItem,
  CCardText,
  CCardTitle,
  CImage,
} from '@coreui/react'
import { TagsStepProps } from '../types'
import { LoadingSpinner } from './LoadingSpinner'
import { t } from 'i18next'
import { SubscriptionService } from '../../../services/SubscriptionService'
import { EasyContext } from '../../../components/context/EasyContext'
import EasyDirectoryLogo from '../../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'

export const TagsStep: React.FC<TagsStepProps> = ({
  onNext,
  personalTags,
  setPersonalTags,
  migratePersonalTags,
  setMigratePersonalTags,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const { accessToken } = useContext(EasyContext)
  const subsService = new SubscriptionService(accessToken)

  useEffect(() => {
    let mounted = true

    const loadTags = async () => {
      try {
        const tags = await subsService.getOldEasyDirectoryPersonalTags()

        if (mounted && tags) {
          setPersonalTags(tags)
        }
      } catch (err) {
        if (mounted) {
          setError(t('EasyDirectory.MigrationWizard.Tags.LoadError'))
          console.error('Error loading tags:', err)
        }
      } finally {
        if (mounted) {
          setIsLoading(false)
        }
      }
    }

    loadTags()
    return () => {
      mounted = false
    }
  }, [])

  if (isLoading) return <LoadingSpinner text={t('EasyDirectory.MigrationWizard.Common.Loading')} />
  if (error) return <CAlert color="danger">{error}</CAlert>

  return (
    <>
    <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
    <CCard>
      <CCardBody>
        <h4>{t('EasyDirectory.MigrationWizard.Tags.Title')}</h4>
        {personalTags.length === 0 && (
          <CAlert color="info">{t('EasyDirectory.MigrationWizard.Tags.NoTags')}</CAlert>
        )}
        {personalTags.length > 0 && (
          <>
            <CCardTitle>{t('EasyDirectory.MigrationWizard.Tags.PersonalTags')}</CCardTitle>
            <CCardText>{t('EasyDirectory.MigrationWizard.Tags.Description')}</CCardText>
            <div className="mb-4">
              <div className="mb-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <CListGroup>
                  {personalTags.map((tag: string) => (
                    <CListGroupItem key={tag}>{tag}</CListGroupItem>
                  ))}
                </CListGroup>
              </div>
              <CFormCheck
                id="migrateTags"
                label={t('EasyDirectory.MigrationWizard.Tags.MigrateOption')}
                checked={migratePersonalTags}
                onChange={(e) => setMigratePersonalTags(e.target.checked)}
              />
            </div>
          </>
        )}

        <div className="d-flex justify-content-between">
          <CButton color="primary" onClick={onNext}>
            {t('EasyDirectory.MigrationWizard.Common.Next')}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
    </>
  )
}

