import {
  Combobox,
  useId,
  Option,
  shorthands,
  makeStyles,
  ComboboxProps,
  Avatar,
  Tree,
  TreeItem,
  TreeItemLayout,
  Tooltip,
  FluentProvider,
  webLightTheme,
  InfoLabel,
} from '@fluentui/react-components'
import { LockClosed20Regular, ChannelShareRegular } from '@fluentui/react-icons'

import { Virtualizer, useStaticVirtualizerMeasure } from '@fluentui/react-virtualizer';
import { useCallback, useContext, useEffect, useState } from 'react'
import { CPlaceholder, CSpinner } from '@coreui/react'
import { useTranslation } from 'react-i18next'
import { Channel, EasyTeam, EasyPickerDropdown, EasyGroup } from '../common/interfaces'
import { ConfigurationService } from '../services/ConfigurationService'
import { GraphService } from '../services/GraphService'
import { Team } from '@microsoft/mgt-react';
import { EasyContext } from './context/EasyContext'

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: 'grid',
    gridTemplateRows: 'repeat(1fr)',
    justifyItems: 'start',
    ...shorthands.gap('2px'),
    width: '100%',
    maxWidth: '100%',
  },
  listbox: {
    maxHeight: '250px',
    minWidth: '500px',
  },
  option: {
    height: '32px',
  },
})

export const EasyTeamsChannelPicker = (props) => {
  const { t } = useTranslation()

  let typingTimeout

  const itemHeight = 32 //This should match the height of each item in the listbox

  const easyContext = useContext(EasyContext)

  const [channel, setChannel] = useState<Channel>()
  const [team, setTeam] = useState<Team>()

  const comboId = useId('combo-default')
  const styles = useStyles()
  const [isOpen, setIsOpen] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [hasMoreTeams, setHasMoreTeams] = useState(true)
  const [isSearching, setIsSearching] = useState(false)
  const [loadingList, setLoadingList] = useState<string[]>([])

  const graphService = new GraphService(easyContext.accessToken, false)
  const configurationService = new ConfigurationService(easyContext?.accessToken)

  const [easyPickerDropdown, setEasyPickerDropdown] = useState<EasyPickerDropdown>()
  const [matchingOptions, setMatchingOptions] = useState<EasyGroup[]>([])

  const { virtualizerLength, bufferItems, bufferSize, scrollRef, containerSizeRef } =
    useStaticVirtualizerMeasure<HTMLElement>({
      defaultItemSize: 10,
      direction: 'vertical',
    })

  const loadMoreTeams = useCallback((dropdown: EasyPickerDropdown) => {
    setIsLoadingMore(true)
    graphService.getMoreGroups(dropdown.nextLink).then((loadedDropdown) => {
      if (loadedDropdown.easyGroups.length > 0) {
        dropdown.easyGroups = dropdown?.easyGroups.concat(loadedDropdown?.easyGroups)
        dropdown.nextLink = loadedDropdown.nextLink
        setEasyPickerDropdown(dropdown)
      } else {
        setHasMoreTeams(false)
      }
      setIsLoadingMore(false)
    })
  }, [])

  useEffect(() => {
    const getTeamInformation = async (selectedTeams, selectedChannel) => {
      setIsLoading(true)
      const [teamInfo, channelInfo, easyGroupDropdown] = await Promise.all([
        configurationService.GetTeamInformation(selectedTeams),
        configurationService.GetChannelInformation(selectedTeams, selectedChannel),
        graphService.preLoadGroups(),
      ])

      if (teamInfo) {
        setTeam(teamInfo)
      } else {
        setTeam(null)
      }

      if (channelInfo) {
        setChannel(channelInfo)
      } else {
        setChannel(null)
      }

      if (easyGroupDropdown) {
        setEasyPickerDropdown(easyGroupDropdown)
      }

      setIsLoading(false)
    }

    getTeamInformation(props.selectedTeams, props.selectedChannel);
    
  }, [props.selectedTeams, props.selectedChannel])

  const OnChange: ComboboxProps['onChange'] = (event) => {
    const value = event.target.value.trim()
    clearTimeout(typingTimeout)

    if (value.length >= 3) {
      typingTimeout = setTimeout(() => {
        setIsSearching(true)
        graphService.getGoupsByName(value).then((result) => {
          setIsSearching(false)
          setIsOpen(true)
          setMatchingOptions([...result])
        })
      }, 300)
    } else {
      setMatchingOptions([...easyPickerDropdown.easyGroups])
    }
  }

  const OnFocus: ComboboxProps['onFocus'] = () => {
    setIsOpen(true)
  }

  const onOptionSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
    event.preventDefault()
    if (data.optionText !== undefined) {
      setIsOpen(false)
    }
  }

  const onScrolling = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target
    const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight
    if (isBottom) {
      if (hasMoreTeams && !isLoading) {
        loadMoreTeams(easyPickerDropdown)
      }
    }
  }

  /**
   * Loads the channels if Dropdown toggles.
   * @param group
   */
  const loadSubTree = (group) => {
    if (group?.channels === undefined) {
      setLoadingList(group?.id)
      group.channels = []
      graphService.getChannelsByGroup(group?.id).then((results) => {
          group?.channels.push(...results)
          setLoadingList([])
        })
        .catch((error) => console.error('Error loading channels:', error))
    }
  }

  useEffect(() => {
    if (easyPickerDropdown?.easyGroups) {
      setMatchingOptions(easyPickerDropdown?.easyGroups)
    }
    if (easyPickerDropdown?.nextLink !== undefined && easyPickerDropdown?.nextLink !== '') {
      setHasMoreTeams(true)
    }
  }, [easyPickerDropdown?.easyGroups, easyPickerDropdown?.nextLink])

  return (
    <FluentProvider theme={webLightTheme}>
      <div className={styles.root}>
        {isLoading ? <CPlaceholder animation="glow" xs={8}><CPlaceholder xs={7} /></CPlaceholder> :
          <InfoLabel
            className="channel-picker--info"
            info={
              <>
                {t("ResultLimitedTo100")}
              </>
            }
            {...props}
          >
            <Tooltip content={t("ThreeLettersRequired")} relationship="label" {...props}>
              <Combobox
                listbox={{ ref: scrollRef, className: styles.listbox, onScroll: onScrolling }}
                open={isOpen}
                freeform
                allowFreeform
                clearable
                readOnly={team && channel}
                aria-labelledby={comboId}
                onFocus={OnFocus}
                onBlur={() => setIsOpen(false)}
                onChange={OnChange}
                onOptionSelect={onOptionSelect}
                placeholder={t("ChannelSelectPlaceholder")}
                defaultSelectedOptions={channel !== undefined ? [channel?.id] : []}
                defaultValue={team && channel ? team?.displayName + " > " + channel?.displayName : []}
                onScroll={onScrolling}
                {...props}>
                {isSearching ?
                  <div className="text-center"><CSpinner size="sm" /></div>
                  :
                  <Virtualizer
                    numItems={matchingOptions?.length}
                    virtualizerLength={virtualizerLength}
                    bufferItems={bufferItems}
                    bufferSize={bufferSize}
                    itemSize={itemHeight} 
                    containerSizeRef={undefined}>
                    {(index) => {
                      return (<div key={index}>
                        <Tree key={matchingOptions[index].displayName} aria-label="Default">
                          <TreeItem itemType="branch">
                            <TreeItemLayout onClick={() => loadSubTree(matchingOptions[index])} iconBefore={matchingOptions[index].visibility === "private" && <LockClosed20Regular />}>
                              <Avatar color="colorful" name={matchingOptions[index].displayName} /> &nbsp;
                              {matchingOptions[index].displayName}
                            </TreeItemLayout>
                            <Tree>
                              {loadingList.includes(matchingOptions[index].id) ? <div className="text-center"><CSpinner size="sm" /></div> : <>
                                {matchingOptions[index]?.channels?.map((channelOption) => (
                                  <TreeItem key={channelOption.id} itemType="leaf">
                                    {channelOption.membershipType === "standard" ?
                                      <Option className={styles.option}
                                        aria-posinset={index}
                                        aria-setsize={1}
                                        text={matchingOptions[index].displayName + " > " + channelOption.displayName}
                                        value={JSON.stringify({ channel: channelOption, voiceAppId: props.voiceAppId })
                                        }>
                                        <TreeItemLayout>
                                          {channelOption.displayName}
                                        </TreeItemLayout>
                                      </Option>
                                      :
                                      <Tooltip content={channelOption.membershipType === "private" ? t("PrivateChannelNotAvailable") : t("SharedChannelNotAvailable")} relationship="label" {...props}>
                                        <Option text='' disabled>
                                          <TreeItemLayout iconBefore={channelOption.membershipType === "private" ? <LockClosed20Regular /> : channelOption.membershipType === "shared" || channelOption.membershipType === "unknownFutureValue" ? <ChannelShareRegular /> : <></>}>
                                            {channelOption.displayName}
                                          </TreeItemLayout>
                                        </Option>
                                      </Tooltip>
                                    }
                                  </TreeItem>
                                ))}
                              </>}
                            </Tree>
                          </TreeItem>
                        </Tree>
                      </div>);
                    }}
                  </Virtualizer>
                }
                {isLoadingMore && <Tree key="loading" aria-label="Default">
                  <div className="text-center"><CSpinner size="sm" /></div>
                </Tree>}
                {matchingOptions?.length === 0 && t("NoResultFound")}
              </Combobox>
            </Tooltip>
          </InfoLabel>}

      </div>
    </FluentProvider>
  );
};

