import { CFormSelect, CSpinner } from "@coreui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GraphService } from "../services/GraphService";
import { EasyContext } from "./context/EasyContext";

const EasyPlannerPicker = (props) => {
    const { t } = useTranslation();
    const [planOptions, setPlanOptions] = useState([]);
    const [loadingPlanList, setLoadingPlanList] = useState(false);
    const [loadingBucketList, setLoadingBucketList] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState("");
    const [selectedBucket, setSelectedBucket] = useState("");
    const [bucketOptions, setBucketOptions] = useState([]);

    const easyContext = useContext(EasyContext);

    const graphService = new GraphService(easyContext.accessToken, true);

    useEffect(() => {
        if (props.planId) {
            setSelectedPlan(props.planId)
        }
        if (props.bucketId) {
            setSelectedBucket(props.bucketId)
        }
    }, []);

    useEffect(() => {
        if (props.teamsId !== null) {
            setLoadingPlanList(true);
            graphService.getAssociatedPlannerPlans(props.teamsId).then(plans => {
                if (plans.length > 0) {
                    const newPlanOptions = plans.map((plan) => {
                        return <option selected={props.planId === plan.id} key={plan.id} value={plan.id}>{plan.title}</option>;
                    });
                    setPlanOptions([<option key="0" value="">- {t("PlanDeactivated")} -</option>, ...newPlanOptions]);
                } else {
                    setPlanOptions([<option key="no-result" value="" disabled>{t("NoPlannerFound")}</option>]);
                }
                setLoadingPlanList(false);
            });
        } else {
            setPlanOptions([<option key="no-result" value="" disabled>{t("NoPlannerFound")}</option>]);
        }
    }, [props.teamsId]);

    useEffect(() => {
        if (selectedPlan !== "") {
            setLoadingBucketList(true);
            graphService.getAllBucketsFromPlanner(selectedPlan).then(plans => {
                if (plans.length > 0) {
                    const newBucketOptions = plans.map((bucket) => {
                        return <option selected={props.bucketId === bucket.id} key={bucket.id} value={bucket.id}>{bucket.name}</option>;
                    });
                    setBucketOptions([<option key="1" value="">- {t("ChooseBucket")} -</option>, ...newBucketOptions]);
                } else {
                    setBucketOptions([<option key="no-result" value="" disabled>{t("NoBucketFound")}</option>]);
                }
                setLoadingBucketList(false);
            });
        } else {
            setBucketOptions([<option key="no-result" value="" disabled>{t("NoPlannerFound")}</option>]);
        }
    }, [selectedPlan]);
    return (<>
        {loadingPlanList ? <div className="d-flex justify-content-center">
            <CSpinner />
        </div> :
            <CFormSelect size="sm" className="mb-3" onChange={(e) => {
                setSelectedPlan(e.target.value);
                setSelectedBucket("");
                if (e.target.value === "" && selectedBucket !== "") {
                    props.update("", "");
                }
            }} >
                {planOptions.map(plan => {
                    return plan;
                })}
            </CFormSelect>}

        {selectedPlan !== "" && (loadingBucketList ? <div className="d-flex justify-content-center">
            <CSpinner />
        </div> :
            <CFormSelect size="sm" className="mb-3" onChange={(e) => {
                setSelectedBucket(e.target.value);
                props.update(selectedPlan, e.target.value);

            }} >
                {bucketOptions.map(plan => {
                    return plan;
                })}
            </CFormSelect>)}
    </>);
};

export default EasyPlannerPicker;