import { useState, useEffect, useContext, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { CContainer, CRow, CCol } from '@coreui/react'
import { FinishStep } from './FinishStep'
import { MigrationStep } from './MigrationStep'
import { SubscriptionStep } from './SubscriptionStep'
import { TagsStep } from './TagsStep'
import { WelcomeStep } from './WelcomeStep'
import ConsentStep from './ConsentStep'
import { EasyContext } from '../../../components/context/EasyContext'
import ConfigurationConsentStep from './ConfigurationConsentStep'

interface MigrationState {
  currentStep: number
  subscriptionData: {
    subscriptionName: string
    company: string
    address: string
    city: string
    postalCode: string
    country: string
  }
  personalTags: string[]
  migratePersonalTags: boolean
}

const initialState: MigrationState = {
  currentStep: 1,
  subscriptionData: {
    subscriptionName: '',
    company: '',
    address: '',
    city: '',
    postalCode: '',
    country: 'CH',
  },
  personalTags: [],
  migratePersonalTags: false,
}

const MigrationWizard: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [state, setState] = useState<MigrationState>(initialState)
  const { accessToken } = useContext(EasyContext);
  
  useEffect(() => {
    localStorage.removeItem('migrationState')
    return () => {
      localStorage.removeItem('migrationState')
    }
  }, [])

  const updateUrl = useCallback(
    (step: number) => {
      const searchParams = new URLSearchParams(location.search)
      if (searchParams.get('step') !== step.toString()) {
        searchParams.set('step', step.toString())
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true })
      }
    },
    [navigate, location.pathname],
  )

  const handleNext = useCallback(() => {
    setState((prev) => {
      const nextStep = prev.currentStep + 1
      updateUrl(nextStep)
      return { ...prev, currentStep: nextStep }
    })
  }, [updateUrl])

  const handlePrevious = useCallback(() => {
    setState((prev) => {
      const prevStep = prev.currentStep - 1
      updateUrl(prevStep)
      return { ...prev, currentStep: prevStep }
    })
  }, [updateUrl])

  const handleFinish = useCallback(() => {
    localStorage.removeItem('migrationState')
    navigate('/easydirectory')
  }, [navigate])

  const renderStep = () => {
    switch (state.currentStep) {
      case 1:
        return <WelcomeStep onNext={handleNext} />
      case 2:
        return (
          <ConsentStep
            onNext={handleNext}
            onPrevious={handlePrevious} />
        )
      case 3: return (
        <ConfigurationConsentStep 
            onNext={handleNext}
            onPrevious={handlePrevious}/>
      )
      case 4:
        return (
          <SubscriptionStep
            onNext={handleNext}
            onPrevious={handlePrevious}
            data={state.subscriptionData}
            setData={(data) => setState((prev) => ({ ...prev, subscriptionData: data }))}
          />
        )
      case 5:
        return (
          <TagsStep
            onNext={handleNext}
            onPrevious={handlePrevious}
            personalTags={state.personalTags}
            setPersonalTags={(tags) => setState((prev) => ({ ...prev, personalTags: tags }))}
            migratePersonalTags={state.migratePersonalTags}
            setMigratePersonalTags={(migrate) =>
              setState((prev) => ({ ...prev, migratePersonalTags: migrate }))
            }
          />
        )
      case 6:
        return (
          <MigrationStep
            onNext={handleNext}
            onPrevious={handlePrevious}
            accessToken={accessToken}
            migrateTags={state.migratePersonalTags}
          />
        )
      case 7:
        return <FinishStep onFinish={handleFinish} />
      default:
        navigate('/404')
        return null
    }
  }

  return (
    <CContainer>
      <br />
      {/* <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br /> */}
      <CRow>
        <CCol lg={10} className="mx-auto">
          {renderStep()}
        </CCol>
      </CRow>
    </CContainer>
  )
}

export default MigrationWizard

