import { InteractionType } from "@azure/msal-browser";
import { useMsalAuthentication } from "@azure/msal-react";
import { CButton } from "@coreui/react";

const LoginButton = () => {
    const request = {
        scopes: ["User.Read"]
    }

    const { login, error } = useMsalAuthentication(InteractionType.Redirect, request);
    
    return (
        <CButton onClick={() => login(InteractionType.Redirect, request)}>Login</CButton>
    )
}

export default LoginButton