import { memo } from 'react'
import { CCard, CCardBody, CImage, CSpinner } from '@coreui/react'
import EasyDirectoryLogo from '../../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'

interface LoadingSpinnerProps {
  text?: string
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = memo(({ text }) => {
  return (
    <>
    <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
    <CCard>
      <CCardBody>
        <div className="d-flex justify-content-center align-items-center p-5">
          <CSpinner color="primary" />
          {text && <span className="ms-2">{text}</span>}
        </div>
      </CCardBody>
    </CCard>
    </>
  )
})

LoadingSpinner.displayName = 'LoadingSpinner'
