import { useState, useEffect, useContext } from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CFormInput,
  CFormSelect,
  CFormLabel,
  CFormTextarea,
  CAlert,
  CSpinner,
  CButton,
  CRow,
  CCol,
  CCardTitle,
  CCardSubtitle,
  CToaster,
  CToast,
  CToastBody,
  CToastHeader,
  CImage,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilWarning, cilInfo, cilCheckCircle } from '@coreui/icons'
import { t } from 'i18next'
import countries from '../../../assets/country-by-abbreviation.json'
import { SubscriptionEnvelop } from '../../../common/interfaces'
import { SubscriptionService } from '../../../services/SubscriptionService'
import { EasyContext } from '../../../components/context/EasyContext'
import { useTranslation } from 'react-i18next'
import EasyDirectoryLogo from '../../../assets/brand/TCF_EasyDirectory_Logo_RGB.png'

interface SubscriptionStepProps {
  onNext: () => void
  onPrevious: () => void
  data: SubscriptionFormData
  setData: (data: SubscriptionFormData) => void
}

interface SubscriptionFormData {
  subscriptionName: string
  company: string
  address: string
  city: string
  postalCode: string
  country: string
}

export const SubscriptionStep: React.FC<SubscriptionStepProps> = ({
  onNext,
  data,
  onPrevious,
  setData,
}) => {
  const { accessToken } = useContext(EasyContext)
  const subscriptionService = new SubscriptionService(accessToken)

  const [isLoading, setIsLoading] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [validated, setValidated] = useState(false)
  const [subscription, setSubscription] = useState<SubscriptionEnvelop | null>(null)
  const [noSubscriptionFound, setNoSubscriptionFound] = useState(false)
  const [toast, setToast] = useState({ visible: false, message: '', color: 'success' })
  const { t } = useTranslation();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        setIsPageLoading(true)
        const subscriptionData = await subscriptionService.getOldEasyDirectorySubscription()

        if (!subscriptionData) {
          setNoSubscriptionFound(true)
          return
        }

        setSubscription(subscriptionData)
        setData({
          subscriptionName: subscriptionData.data.subscription_name || '',
          company: subscriptionData.data.customer?.name || '',
          address: subscriptionData.data.customer?.address || '',
          city: subscriptionData.data.customer?.city || '',
          postalCode: subscriptionData.data.customer?.postalCode || '',
          country: subscriptionData.data.customer?.country || 'CH',
        })
      } catch (err) {
        setError(t('EasyDirectory.MigrationWizard.Subscription.LoadError'))
        console.error('Error loading subscription:', err)
      } finally {
        setIsPageLoading(false)
      }
    }

    fetchSubscription()
  }, [accessToken])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget

    if (!form.checkValidity()) {
      event.stopPropagation()
      setValidated(true)
      return
    }

    setIsLoading(true)
    setError(null)

    try {
      const updatedSubscription: SubscriptionEnvelop = {
        ...subscription!,
        data: {
          ...subscription!.data,
          subscription_name: data.subscriptionName,
          customer: {
            name: data.company,
            address: data.address,
            city: data.city,
            postalCode: data.postalCode,
            country: data.country,
            tenantId: subscription?.data.tenant_id || '',
            id: subscription?.data.customer?.id || '',
            isMSStoreCustomer: subscription?.data.customer?.isMSStoreCustomer || false,
          },
        },
      }

      const success = await subscriptionService.migrateSubscription(updatedSubscription)

      if (!success) {
        throw new Error('Failed to update subscription')
      }

      setToast({
        visible: true,
        message: t('EasyDirectory.MigrationWizard.Subscription.UpdateSuccess'),
        color: 'success',
      })

      setTimeout(() => {
        onNext()
      }, 1000)
    } catch (err) {
      console.error('Error saving subscription:', err)
      setToast({
        visible: true,
        message: t('EasyDirectory.MigrationWizard.Subscription.UpdateError'),
        color: 'danger',
      })
    } finally {
      setIsLoading(false)
    }
  }

  if (isPageLoading) {
    return (
      <>
        <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
        <CCard>
          <CCardBody>
            <div className="d-flex justify-content-center align-items-center p-5">
              <CSpinner color="primary" />
              <span className="ms-2">{t('EasyDirectory.MigrationWizard.Subscription.Loading')}</span>
            </div>
          </CCardBody>
        </CCard>
      </>
    )
  }

  if (noSubscriptionFound) {
    return (
      <>
        <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
        <CCard>
          <CCardBody>
            <h4>{t('EasyDirectory.MigrationWizard.Subscription.Title')}</h4>
            <CAlert color="info" className="d-flex align-items-center">
              <CIcon icon={cilInfo} className="flex-shrink-0 me-2" width={24} height={24} />
              <div>{t('EasyDirectory.MigrationWizard.Subscription.NoSubscription')}</div>
            </CAlert>
            <div className="d-flex justify-content-between mt-4">
              <CButton color="light" onClick={onPrevious}>
                {t('EasyDirectory.MigrationWizard.Common.Previous')}
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </>
    )
  }

  if (error) {
    return (
      <>
        <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
        <CCard>
          <CCardHeader>
            <CCardTitle>{t('EasyDirectory.MigrationWizard.Subscription.Title')}</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CAlert color="danger" className="d-flex align-items-center">
              <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
              <div>{error}</div>
            </CAlert>
            <div className="d-flex justify-content-between mt-4">
              <CButton color="light" onClick={onPrevious}>
                {t('EasyDirectory.MigrationWizard.Common.Previous')}
              </CButton>
              <CButton color="primary" onClick={() => window.location.reload()}>
                {t('EasyDirectory.MigrationWizard.Common.Retry')}
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </>
    )
  }

  if (!subscription) {
    return (
      <>
        <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
        <CCard>
          <CCardHeader>
            <CCardTitle>{t('EasyDirectory.MigrationWizard.Subscription.Title')}</CCardTitle>
          </CCardHeader>
          <CCardBody>
            <CAlert color="warning" className="d-flex align-items-center">
              <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
              <div>{t('EasyDirectory.MigrationWizard.Subscription.LoadError')}</div>
            </CAlert>
            <div className="d-flex justify-content-between mt-4">
              <CButton color="light" onClick={onPrevious}>
                {t('EasyDirectory.MigrationWizard.Common.Previous')}
              </CButton>
              <CButton color="primary" onClick={() => window.location.reload()}>
                {t('EasyDirectory.MigrationWizard.Common.Retry')}
              </CButton>
            </div>
          </CCardBody>
        </CCard>
      </>
    )
  }

  return (
    <>
      <CImage fluid src={EasyDirectoryLogo} alt="Easy Directory" width={370} /><br /><br />
      <CToaster placement="top-end">
        <CToast
          visible={toast.visible}
          color={toast.color}
          delay={4000}
          onClose={() => setToast({ ...toast, visible: false })}
        >
          <CToastHeader closeButton>
            <CIcon
              icon={toast.color === 'success' ? cilCheckCircle : cilWarning}
              className="me-2"
            />
            {toast.color === 'success'
              ? t('EasyDirectory.MigrationWizard.Common.Success')
              : t('EasyDirectory.MigrationWizard.Common.Error')}
          </CToastHeader>
          <CToastBody>{toast.message}</CToastBody>
        </CToast>
      </CToaster>

      <CCard>
        <CCardHeader>
          <CCardTitle>{t('EasyDirectory.MigrationWizard.Subscription.Title')}</CCardTitle>
          <CCardSubtitle className="mt-2 text-medium-emphasis">
            {t('EasyDirectory.MigrationWizard.Subscription.SubTitle')}
          </CCardSubtitle>
        </CCardHeader>
        <CCardBody>
          {error && (
            <CAlert color="danger" className="d-flex align-items-center mb-4">
              <CIcon icon={cilWarning} className="flex-shrink-0 me-2" width={24} height={24} />
              <div>{error}</div>
            </CAlert>
          )}

          <div className={`position-relative ${isLoading ? 'pe-none opacity-50' : ''}`}>
            <CForm
              className="needs-validation"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
            >
              <div className="mb-4">
                <CRow className="mb-3">
                  <CFormLabel htmlFor="subscriptionStatus" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.Status')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="subscriptionStatus"
                      value={subscriptionService.getSubscriptionStatus(subscription?.data.state)}
                      readOnly
                      plainText
                    />
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CFormLabel htmlFor="seatCount" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.Seats')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="seatCount"
                      value={subscription?.data.seatQuantity || 0}
                      readOnly
                      plainText
                    />
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CFormLabel htmlFor="purchaserEmail" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.PurchaserEmail')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="purchaserEmail"
                      value={subscription?.data.purchaser?.email || ''}
                      readOnly
                      plainText
                    />
                  </CCol>
                </CRow>
              </div>
              <div className="mb-4">
                <CRow className="mb-3">
                  <CFormLabel htmlFor="subscriptionName" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.SubscriptionName')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="subscriptionName"
                      value={data.subscriptionName}
                      onChange={(e) => setData({ ...data, subscriptionName: e.target.value })}
                      required
                    />
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CFormLabel htmlFor="company" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.Company')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="company"
                      value={data.company}
                      onChange={(e) => setData({ ...data, company: e.target.value })}
                      required
                    />
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CFormLabel htmlFor="address" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.Address')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormTextarea
                      id="address"
                      rows={2}
                      value={data.address}
                      onChange={(e) => setData({ ...data, address: e.target.value })}
                      required
                    />
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CFormLabel htmlFor="city" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.City')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="city"
                      value={data.city}
                      onChange={(e) => setData({ ...data, city: e.target.value })}
                      required
                    />
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CFormLabel htmlFor="postalCode" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.PostalCode')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormInput
                      type="text"
                      id="postalCode"
                      value={data.postalCode}
                      onChange={(e) => setData({ ...data, postalCode: e.target.value })}
                      required
                    />
                  </CCol>
                </CRow>

                <CRow className="mb-3">
                  <CFormLabel htmlFor="country" className="col-sm-3 col-form-label">
                    {t('EasyDirectory.MigrationWizard.Subscription.Country')}
                  </CFormLabel>
                  <CCol sm={9}>
                    <CFormSelect
                      id="country"
                      value={data.country}
                      onChange={(e) => setData({ ...data, country: e.target.value })}
                      required
                    >
                      <option value="">
                        {t('EasyDirectory.MigrationWizard.Subscription.SelectCountry')}
                      </option>
                      {countries.map((country) => (
                        <option key={country.abbreviation} value={country.abbreviation}>
                          {country.country}
                        </option>
                      ))}
                    </CFormSelect>
                  </CCol>
                </CRow>
              </div>
              <div className="d-flex justify-content-between mt-4">
                <CButton color="light" onClick={onPrevious}>
                  {t('EasyDirectory.MigrationWizard.Common.Previous')}
                </CButton>
                <CButton color="primary" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <>
                      <CSpinner size="sm" className="me-2" />
                      {t('EasyDirectory.MigrationWizard.Common.Saving')}
                    </>
                  ) : (
                    t('EasyDirectory.MigrationWizard.Common.Next')
                  )}
                </CButton>
              </div>
            </CForm>
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}
