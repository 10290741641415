import { createContext } from "react";
import { EasyProductLicenses, Subscription } from "../../common/interfaces";

export const EasyContext = createContext<{
  accessToken?: string;
  userDisplayName: string;
  userId: string;
  tenantId: string;
  subscriptions?: Subscription[];
  productLicenses: EasyProductLicenses
}>({
  accessToken: undefined,
  userDisplayName: "",
  userId: "",
  tenantId: "",
  subscriptions: [],
  productLicenses: undefined
});
