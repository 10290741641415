import {
  CCard,
  CCardBody,
  CCardTitle,
  CCardText,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CButton,
  CModalFooter,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CFormInput,
  CCol,
  CFormLabel,
  CRow,
  CForm,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
} from '@coreui/react'
import { useTranslation } from "react-i18next";
import CIcon from '@coreui/icons-react';
import { cilCheckAlt, cilPencil, cilShareAlt, cilX } from '@coreui/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { SubscriptionService } from '../../services/SubscriptionService';
import { Subscription } from '../../common/interfaces';
import { EasyContext } from '../../components/context/EasyContext';
import { useIsAuthenticated } from '@azure/msal-react';

const SubscriptionManager = () => {
  const { t } = useTranslation();

  const isAuthenticated = useIsAuthenticated();
  const easyContext = useContext(EasyContext);
  const subscriptionService = new SubscriptionService(easyContext.accessToken);

  const [isReseller, setIsReseller] = useState(false);
  const [subscriptionToAssign, setSubscriptionToAssign] = useState("");
  const [tenantToAssign, setTenantToAssign] = useState("");
  const [subscriptionAssignmentModal, setSubscriptionAssignmentModal] = useState(false);
  const [resellerId, setResellerId] = useState<string>("");
  const [resellerSubscriptions, setResellerSubscriptions] = useState<Subscription[]>([]);
  const [loadingMsg, setLoadingMsg] = useState(t("Loading"));
  const [isPageConfigLoading, setIsPageConfigLoading] = useState(false);
  const [validated, setValidated] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [toast, addToast] = useState<any>();
  const toaster = useRef();


  const successfulToast = (
    <CToast autohide={true} visible={true} color="success" role="img" className="text-white align-items-center" onClose={() => { setSuccessMsg("") }}>
      <div className="d-flex">
        <CToastBody>{successMsg}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )

  const errorToast = (
    <CToast autohide={true} visible={true} color="danger" role="img" className="text-white align-items-center" onClose={() => { setErrorMsg("") }}>
      <div className="d-flex">
        <CToastBody>{errorMsg}</CToastBody>
        <CToastClose className="me-2 m-auto" white />
      </div>
    </CToast>
  )

  useEffect(() => {
    const initializeReseller = async () => {
      const isReseller = await subscriptionService.IsReseller(easyContext.subscriptions);

      setIsReseller(isReseller);

      if (isReseller) {
        easyContext.subscriptions?.forEach((subscription: Subscription) => {
          setResellerId(easyContext.tenantId);
        });

        getResellerSubscriptions(easyContext.tenantId);
      }
    }

    initializeReseller();

  }, [easyContext.subscriptions, easyContext.tenantId]);


  useEffect(() => {
    if (errorMsg !== null && errorMsg !== "") {
      addToast(errorToast);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (successMsg !== null && successMsg !== "") {
      addToast(successfulToast);
    }
  }, [successMsg]);

  useEffect(() => {
    if (validated === true) {
      const assignSubscription = async () => await subscriptionService.assignResellerSubscription(subscriptionToAssign, tenantToAssign).then((subscription) => {
        if (subscription !== null) {
          setSubscriptionAssignmentModal(false);

          setSubscriptionToAssign("");
          setTenantToAssign("");

          getResellerSubscriptions(easyContext.tenantId);
          setSuccessMsg("Subscription was assigned successfully. Please refresh this page to view the assigned license.");
        }
        else {
          // Raise error
          setErrorMsg("We cannot assign your subscription.");
        }
      }).catch((e) => {
        // Raise error
        setErrorMsg("We cannot assign your subscription: " + e.message.message);
      });

      assignSubscription();
    }
  }, [validated]);

  const handleSubmit = (event) => {
    const form = event.currentTarget

    if (form[1].value !== null && form[1].value !== "") {
      setTenantToAssign(form[1].value)
      setValidated(true);
    }

    event.preventDefault()
    event.stopPropagation()
  }

  const getResellerSubscriptions = async (tenant_id: string) => await subscriptionService.getResellerSubscriptions(tenant_id).then((subscriptions: Subscription[]) => {
    console.log('Reseller subscriptions:', subscriptions);
    setResellerSubscriptions(subscriptions);
    setIsPageConfigLoading(false);
  });

  const RenderSubscriptions = () => {
    // Render configured assignments
    const items = easyContext.subscriptions?.map((subscription: Subscription, index: number) => (
      RenderSubscription(subscription, index)
    ));

    return (items ?
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">#</CTableHeaderCell>
            <CTableHeaderCell scope="col">Subscription Title</CTableHeaderCell>
            <CTableHeaderCell scope="col">Offer</CTableHeaderCell>
            <CTableHeaderCell scope="col">Plan</CTableHeaderCell>
            <CTableHeaderCell scope="col">Is Trial</CTableHeaderCell>
            <CTableHeaderCell scope="col">Seats</CTableHeaderCell>
            <CTableHeaderCell scope="col">Status</CTableHeaderCell>
            <CTableHeaderCell scope="col">Expiration Date</CTableHeaderCell>
            <CTableHeaderCell scope="col"></CTableHeaderCell>
            {isReseller && (
              <CTableHeaderCell scope="col"></CTableHeaderCell>
            )}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {items}
        </CTableBody>
      </CTable> : <>No subscriptions found</>
    );
  }

  const RenderSubscription = (subscription: Subscription, index: number) => {
    return (
      <CTableRow>
        {/* itemKey={"row-" + subscription.subscription_id}> */}
        <CTableDataCell>{index + 1}</CTableDataCell>
        <CTableDataCell>{subscription.subscription_name} </CTableDataCell>
        <CTableDataCell>{subscriptionService.getSubscriptionProductTitle(subscription.offer_id)} </CTableDataCell>
        <CTableDataCell>{subscriptionService.getSubscriptionProductPlan(subscription.plan_id)} </CTableDataCell>
        <CTableDataCell>{subscription.is_free_trial ? <CIcon className="text-success" icon={cilCheckAlt} /> : <CIcon className="text-danger" icon={cilX} />} </CTableDataCell>
        <CTableDataCell>{subscription.seatQuantity} </CTableDataCell>
        <CTableDataCell>{subscriptionService.getSubscriptionStatus(subscription.state)} </CTableDataCell>
        <CTableDataCell>{subscriptionService.getExpirationDate(subscription.term.endDate)} </CTableDataCell>
        <CTableDataCell>
          <CButton href={"/subscriptions/" + subscription.subscription_id}>
            <CIcon icon={cilPencil} />
          </CButton>
        </CTableDataCell>
        <CTableDataCell>
          {isReseller && subscription.state === 3 && (subscription.offer_id.includes("EasyCallReport-NFR") === false && subscription.offer_id.includes("Easy-Call-Report-NFR") === false) ?
            <CButton onClick={() => { setSubscriptionToAssign(subscription.subscription_id); setSubscriptionAssignmentModal(true) }}>
              <CIcon icon={cilShareAlt} />
            </CButton>
            : <></>}
        </CTableDataCell>
      </CTableRow>
    );
  }

  const RenderResellerSubscriptions = () => {

    if (!Array.isArray(resellerSubscriptions)) {
      return <div>No subscriptions found</div>;
    }
    // Render configured assignments
    const items = resellerSubscriptions?.map((subscription, index) => (
      RenderResellerSubscription(subscription, index)
    )) || [];

    return (items ?
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell scope="col">#</CTableHeaderCell>
            <CTableHeaderCell scope="col">Customer Tenant</CTableHeaderCell>
            <CTableHeaderCell scope="col">Offer</CTableHeaderCell>
            <CTableHeaderCell scope="col">Plan</CTableHeaderCell>
            <CTableHeaderCell scope="col">Is Trial</CTableHeaderCell>
            <CTableHeaderCell scope="col">Seats</CTableHeaderCell>
            <CTableHeaderCell scope="col">Status</CTableHeaderCell>
            {/* <CTableHeaderCell scope="col"></CTableHeaderCell> */}
          </CTableRow>
        </CTableHead>
        <CTableBody>
          {items}
        </CTableBody>
      </CTable> : <>No subscriptions found</>
    );
  }

  const RenderResellerSubscription = (subscription: Subscription, index: number) => {
    return (
      <CTableRow>
        {/* itemKey={"row-" + subscription.subscription_id} */}
        <CTableDataCell>{index + 1}</CTableDataCell>
        <CTableDataCell>{subscription.tenant_name ?? subscription.tenant_id} </CTableDataCell>
        <CTableDataCell>{subscriptionService.getSubscriptionProductTitle(subscription.offer_id)} </CTableDataCell>
        <CTableDataCell>{subscriptionService.getSubscriptionProductPlan(subscription.plan_id)} </CTableDataCell>
        <CTableDataCell>{subscription.is_free_trial ? <CIcon className="text-success" icon={cilCheckAlt} /> : <CIcon className="text-danger" icon={cilX} />} </CTableDataCell>
        <CTableDataCell>{subscription.seatQuantity} </CTableDataCell>
        <CTableDataCell>{subscriptionService.getSubscriptionStatus(subscription.state)} </CTableDataCell>
        {/* <CTableDataCell>
          <CButton href={"/subscriptions/" + subscription.subscription_id}>
            <CIcon icon={cilPencil} />
          </CButton>
        </CTableDataCell> */}
      </CTableRow>

    );
  }

  return (isAuthenticated ? (
    <div>
      <CToaster className="p-3" placement="top-end" push={toast} ref={toaster} />
      <br />
      {isPageConfigLoading ?
        <div className="d-flex justify-content-center tcf-loading-background">
          <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
        </div> : <></>
      }
      <h2>{t("Subscription Management")}</h2><br />
      <p>
        On this page you can manage the subscriptions for your tenant. You can
        simply add or remove subscriptions. If you need any kind of help, please
        contact our Support Team.
      </p>
      <CCard>
        <CCardBody>
          <CCardTitle>Your Subscriptions</CCardTitle>
          <CCardText>
            <br />
            {RenderSubscriptions()}
          </CCardText>
        </CCardBody>
      </CCard>
      <br />
      {isReseller === true &&
        <div>
          <CCard>
            <CCardBody>
              <CCardTitle>Customer Subscriptions</CCardTitle>
              <CCardText>
                <br />
                {RenderResellerSubscriptions()}
              </CCardText>
            </CCardBody>
          </CCard>
          <CModal visible={subscriptionAssignmentModal}
            alignment="center"
            onClose={() => setSubscriptionAssignmentModal(false)}
            aria-labelledby="Keep old version">
            <CForm onSubmit={handleSubmit}>
              <CModalHeader>
                <CModalTitle>Assign Subscription</CModalTitle>
              </CModalHeader>
              <CModalBody>
                Please enter the domain or tenant id of your customer, to assign the subscription. <br /><br />
                <b>Caution:</b> After the assignment of a subscription, the subscription is getting locked. <span className="tcf-danger">You cannot modify/re-assign the license anymore.</span>
                <br /><br />
                <CRow className="mb-3">
                  <CFormLabel htmlFor="tenantId" className="col-sm-2 col-form-label">Tenant</CFormLabel>
                  <CCol sm={10} >
                    <CFormInput id="tenantId"
                      text="Please provide the Customer Tenant Id (Guid) or Customer Tenant Domain (following format tenant.domain)"
                      required></CFormInput>
                  </CCol>
                </CRow>

              </CModalBody>
              <CModalFooter>
                <CButton color="secondary" variant="outline" onClick={() => setSubscriptionAssignmentModal(false)}>Cancel</CButton>
                <CButton color="primary" type="submit">Assign Subscription</CButton>
              </CModalFooter>
            </CForm>
          </CModal>
        </div>
      }
      <br />
      <br />
      <br />
    </div>
  ) : (
    <div className="d-flex justify-content-center tcf-loading-background">
      <CSpinner variant="grow" />&nbsp;&nbsp;&nbsp;<h5>{loadingMsg}</h5>
    </div>)
  )
}

export default SubscriptionManager
