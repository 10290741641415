import React, { useContext } from 'react'
import {
  CButton,
  CDropdown,
  CDropdownToggle,
} from '@coreui/react'
import LoginButton from '../auth/LoginButton';
import { EasyContext } from '../context/EasyContext';
import { useIsAuthenticated } from '@azure/msal-react';

const AppHeaderDropdown = () => {
  const isAuthenticated = useIsAuthenticated();
  const easyContext = useContext(EasyContext)
  
  return (
    isAuthenticated ? (
      <div>{easyContext.userDisplayName}</div>
    ) : (
      <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0" caret={false}>
        <LoginButton />
      </CDropdownToggle>
    </CDropdown>
    )
  )
}

export default AppHeaderDropdown
